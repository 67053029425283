
// @ts-nocheck
import Vue from "vue";

import flags from "@/mixins/flags";

export default Vue.extend({
  name: "Flag",
  mixins: [flags],
  props: {
    countryCode: String,
  },
  computed: {
    flag() {
      return this.flags.find((flag) => flag.code === this.countryCode)
        ?.component;
    },
  },
});
