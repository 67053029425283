// @ts-nocheck

// flags downloaded from here: https://flagicons.lipis.dev/
import FlagGb from "@/assets/flags/gb.svg";
import FlagUs from "@/assets/flags/us.svg";

export default {
  components: {
    FlagGb,
    FlagUs,
  },
  data() {
    return {
      flags: [
        {
          code: "gb",
          component: FlagGb,
        },
        {
          code: "us",
          component: FlagUs,
        },
      ],
      isExpanded: false,
    };
  },
};
